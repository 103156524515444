<script>
import Layout from "../layout/main";
import PageHeader from "@/components/static/page-header";

import Table from "@/views/pages/admin/shift/team-members/components/table";
import NewShifts from "@/views/pages/employee/components/newShifts";

export default {
  components: {
    Layout,
    PageHeader,
    Table,
    NewShifts,
  },

  data() {
    return {
      items: [
        {
          text: "Shift Record",
        },
        {
          text: "Shift",
          active: true,
        },
      ],
      currentTabComponent: "NewShifts",
      tabs: [
        {
          id: 0,
          desc: "Available Shifts",
          status: "NewShifts",
          icon: "fa-file",
        },
        {
          id: 1,
          desc: "Requesting Pickup",
          status: "Pending",
          icon: "fa-file",
        },
        {
          id: 2,
          desc: "Approved Shifts",
          status: "Accepted",
          icon: "fa-file",
        },
        {
          id: 3,
          desc: "Shift Release Request",
          status: "ApplyForLeave",
          icon: "fa-file",
        },
        {
          id: 4,
          desc: "Dropped Shift",
          status: "Released",
          icon: "fa-file",
        },
        // {
        //   id: 5,
        //   desc: "Rejected Shifts",
        //   status: "Rejected",
        //   icon: "fa-file",
        // },
        {
          id: 6,
          desc: "Completed Shifts",
          status: 'Completed',
          icon: "fa-file",
        },
      ],
      shifts: {},
      releaseprocessing: false,
      filterStatus : null,
      loading:false,
    };
  },
  watch: {
    '$route.hash':{
        handler(newHash){
          // removing character '#'
          var newStr = newHash.replace('#','')
          this.currentTabComponent = newStr ? newStr : 'NewShifts'
          this.helper();
        },
        immediate: true, 
        deep: true,
    },
  },

  mounted() {
    // this.fetchShiftRequests();
  },
  methods: {
    helper(){
      if(this.currentTabComponent == 'ApplyForLeave'){
          this.filterStatus = 'Apply For Leave'
      }else{ this.filterStatus = this.currentTabComponent}
      this.fetchShiftRequests();

    },
    fetchShiftRequests(url) {
      if(this.currentTabComponent != 'NewShifts')
      {
        this.loading = true;
        url = url ?? "/shift/employee/index";
        this.$axios.get(url,{params: {employee_id : this.currentUser.employee.id  , status: this.filterStatus}}).then((response) => {
          this.loading = false;
          let data = response.data.payload;
          this.shifts = data;
        }).catch(error => {
          this.loading = false;
          this.handleErrorResponse(error.response, "error");
        });
      }
    },

    releaseShift({
      status,
      releaseDocument,
      releaseReason,
      select_release_reason,
      shiftTimeline,
    }) {
      let self = this;
      if (self.releaseprocessing) {
        return;
      }
      self.releaseprocessing = true;
      if (select_release_reason != "other") {
        releaseReason = select_release_reason;
      }

      let fd = new FormData();
      fd.append("release_docs", releaseDocument);
      fd.append("status", status);
      fd.append("area_role_id", shiftTimeline.area_role_id);
      fd.append("shift_id", shiftTimeline.shift_id);
      fd.append("slot_id", shiftTimeline.slot_id);
      fd.append("employee_id", shiftTimeline.employee.id);
      fd.append("action_detail", releaseReason);

      self.$axios
        .post("shift/employee/shift-action/" + shiftTimeline.id, fd , {params : {validateRelease : true}})
        .then((response) => {
          let data = response.data;
          this.shifts = this.shifts.data.filter((obj) => obj.id != shiftTimeline.id);
          self.triggerSwal(data.message, "success");
          this.fetchShiftRequests();
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.releaseprocessing = false;
          this.$refs.releaseTable.select_release_reason = null;
          this.$refs.releaseTable.release_reason = null;
          this.$refs.releaseTable.release_document = null;
          this.$refs.releaseTable.releaseShiftModel = false;
          this.$refs.releaseTable.$v.$reset();
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row">
      <div class="col-12 col-md">
        <ul class="nav nav-pills" role="tablist">
          <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
            <a
              class="nav-link text-dark d-flex align-items-center"
              :class="{
                gradientColor: currentTabComponent === tab.status,
                'text-white': currentTabComponent === tab.status,
              }"
              :id="tab.id"
              @click="currentTabComponent = tab.status"
              data-toggle="tab"
              :href="'#' + currentTabComponent"
              aria-controls="shift-listing"
              role="tab"
              aria-selected="true"
            >
              <span class="d-sm-block">{{ tab.desc }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body under-tabs">
        <div class="row">
          <div class="col-12">
            <div class="tab-content">
              <Table
                :loading="loading"
                v-if="currentTabComponent != 'NewShifts'"
                :shifts="shifts"
                :release-processing="releaseprocessing"
                :status="currentTabComponent"
                @releaseShift="releaseShift($event)"
                ref="releaseTable"
                @paginatedData="fetchShiftRequests($event)"
              ></Table>
              <NewShifts
                :loading="loading"
                @refresh="fetchShiftRequests"
                :shifts="shifts"
                v-else
                :status="currentTabComponent"
              ></NewShifts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>